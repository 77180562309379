<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="editOrderForm"
        @submit.prevent="submitEditOrderForm"
      >
        <v-row>
          <v-col
            cols="6"
            class="pt-0 mt-n2"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <ChooseClientAddressPartial
                  v-if="dataFetched"
                  ref="chooseClientAddressPartial"
                  :is-editing-order="!!editingOrder"
                  :addresses="addresses"
                  :order-data="order"
                  :course-type="course.courseType"
                  :show-address-form="showAddressForm"
                  :specific-client="true"
                  :distance="straightLineDistance"
                  @update="addUpdatedProperty"
                  @addAddress="changeAddressFormStatus(true)"
                  @editAddress="changeAddressFormStatus(true, true)"
                />
              </v-col>

              <template v-if="showAddressForm">
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <div class="form__section-title">
                    {{ chosenAddress? 'Edytuj' : 'Dodaj' }} lokalizację
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class="p-0"
                >
                  <AddressForm
                    actions
                    :editing-address="chosenAddress"
                    :forced-department="department.id"
                    :edit-address-products="true"
                    @close="changeAddressFormStatus(false)"
                    @addNewAddress="addAddress"
                    @editAddress="updateAddress"
                  />
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="pt-0 mt-4"
          >
            <v-row>
              <v-col cols="12">
                <NewCoursePartial
                  v-if="dataFetched"
                  :course-data="course"
                  :order-data="order"
                  is-simple-order-type
                  edit-mode
                  :hide-phone-number="!['Podstawienie', 'Transport'].includes(course.courseType)"
                  @update="addUpdatedProperty"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <NewPaymentPartial
                  ref="payment"
                  :payment-data="payment"
                  :course-type="course.courseType"
                  :client-type="clientType"
                  :aggregate-amount="order.aggregateAmount"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  @update="addUpdatedProperty"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isSubmitDisabled"
        @click="submitEditOrderForm()"
      >
        Edytuj zlecenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit } from '../../utils'
import updateFormValue from '../../mixins/updateFormValue.vue'
import orderFormMixin from '../../mixins/orderFormMixin.vue'
import { NewOrder } from '../../models'
import set from 'lodash/set'

export default {
  mixins: [
    updateFormValue,
    orderFormMixin, // changeAddressFormStatus, fetchClientAddresses, addAddress, updateAddress, addresses
    // order, course, payment, showAddressForm, , chosenAddress, locationDiscount ,destinationCoordinates,
    // department, isProcessing, straightLineDistance, closeDialog, showSnackbar, clientType
    // watch: 'order.addressId'
  ],
  props: {
    editingOrder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      updatedParams: {},
      dataFetched: false
    }
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
    }),
    isSubmitDisabled () {
      return this.showAddressForm || !this.payment.debrisNetValue
    }
  },
  mounted () {
    const { order, payment, course } = new NewOrder(this.editingOrder)
    this.order = order
    this.payment = payment
    this.course = course
    this.clientType = this.editingOrder.client.clientType
    this.fetchClientAddresses()
      .then(() => {
        this.dataFetched = true
      })
  },
  methods: {
    ...mapActions({
      editOrder: 'orders/editItem',
    }),
    addUpdatedProperty (data) {
      this.updateFormValue(data)
      set(this.updatedParams, data.key, data.value)
    },
    submitEditOrderForm () {
      if (this.$refs.editOrderForm.validate()) {
        const payment = { ...this.updatedParams.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.transportNetValue = parseAsBasicUnit(payment.transportNetValue)
        payment.transportGrossValue = parseAsBasicUnit(payment.transportGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)

        const params = {
          ...this.updatedParams.order,
          payment
        }
        delete params.clientId // set on init, not editable
        delete params.payment.vatPercentage // set on init, not editable
        if (!Object.keys(params.payment).length || !Object.values(params.payment).filter(Boolean).length) delete params.payment

        const courseType = this.editingOrder.courseType === 'Transport'
          ? 'transportCourse' : 'leaveCourse'
        params[courseType] = this.updatedParams.course

        this.editOrder({
          tableName: 'orders',
          id: this.editingOrder.id,
          params
        }).then(() => {
          this.closeDialog()
        })
      } else {
        this.showSnackbar({
          type: 'error',
          message: ['Nie wszystkie pola wypełniono poprawnie']
        })
      }
    }
  }
}
</script>
